import React,{useEffect} from "react";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {contact, intigrations} from "../constants/helpers";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import {DigitalServices} from "../constants/helpers";
const DigitalMarketing = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    let data = DigitalServices.find(el => el.id === "3");
    return(
        <>
            <Helmet>
                <title> {data.metaTitle}</title>
                <meta name="description" content={data.shortDesc}/>
                <meta name="keywords" content={data.metaKeyword}/>
                <meta name="author" content="Abdul Hanan"/>
            </Helmet>
            <section className="digi-hero ptb-120">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-6">
                            <div className="digi-hero-text mt-5 mt-lg-0">
                                <div>
                                    <span className="span-arrow">Best Digital Agency</span>
                                    <img src="assets/img/arro-right.svg" alt="arrow" />
                                </div>
                                <h1 className="display-5 fw-bold mb-3">
                                    We Are the <span className="text-orange fw-bold">Brilliants </span>
                                     in Terms of digital Marketing
                                </h1>
                                <p className="m-0">
                                    Digital marketing is the promotion of products/services using digital channels to reach a target audience and convert them into customers. It includes SEO, PPC, social media, content marketing, email marketing, and influencer marketing.
                                </p>
                                <div className="action-btns mt-4 align-items-center d-block d-sm-flex d-lg-flex d-md-flex">
                                    <Link to={contact} className="btn rounded-pill bg-orange me-3">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 cool-md-6">
                            <div className="digi-hero-img text-center position-relative mt-5">
                                <img src="assets/img/digi-h-girl.jpg" className="img-fluid" alt="girl" />
                                <ul className="list-unstyled d-none d-xl-block">
                                    <li>
                                        <img src="assets/img/digital-marketing/hero-shape-1.png" alt="shape" className="" />
                                    </li>
                                    <li>
                                        <img src="assets/img/digital-marketing/hero-shape-2.png" alt="shape" />
                                    </li>
                                    <li>
                                        <img src="assets/img/digital-marketing/hero-shape-3.png" alt="shape" />
                                    </li>
                                    <li>
                                        <img src="assets/img/digital-marketing/hero-shape-4.png" alt="shape" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="digi-why pb-60">
                <div className="container">
                    <div className="row align-content-center justify-content-between">
                        <div className="col-lg-6 col-md-12">
                            <div className="digi-why-left">
                                <img src="assets/img/d-girl-w-p.png" className="img-fluid" alt="Girl" />
                                <ul className="list-unstyled d-none d-md-block">
                                    <li>
                                        <img src="assets/img/dg-shape-1.png" className="shadow img-fluid" alt="shape" />
                                    </li>
                                    <li>
                                        <img src="assets/img/dg-shape-2.png" className="shadow img-fluid" alt="shape" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="digi-why-right pt-4">
                                <div>
                                    <span className="span-arrow">Why Choose Us</span>
                                    <img src="assets/img/arro-right.svg" className="img-fluid" alt="arrow" />
                                </div>
                                <h2>Why Working With Us?</h2>
                                <p>
                                    Digital marketing is the core of today’s strategic marketing techniques. It aids in the promotion and growth of businesses, public agencies, and sole trader companies. As brands compete with one another to gain attention and sales, the impact of digital marketing is considerable.
                                </p>
                                <div>
                                    <ul className="list-unstyled mb-0 mt-5">
                                        <li className="d-flex p-3 align-items-center">
                                            <div className="me-3">
                                                <img src="assets/img/icons/dw-i-1.svg" alt="icon" />
                                            </div>
                                            <div>
                                                <h4 className="mb-0">250+</h4>
                                                <span>Happy Customer</span>
                                            </div>
                                        </li>
                                        <li className="pe-4 d-flex p-3 align-items-center">
                                            <div className="me-3">
                                                <img src="assets/img/icons/dw-i-2.svg" className="img-fluid" alt="icon" />
                                            </div>
                                            <div>
                                                <h4 className="mb-0">540+</h4>
                                                <span>Succesfull Project</span>
                                            </div>
                                        </li>
                                        <li className="pe-4 d-flex p-3 align-items-center">
                                            <div className="me-3">
                                                <img src="assets/img/icons/dw-i-3.svg" className="img-fluid" alt="icon" />
                                            </div>
                                            <div>
                                                <h4 className="mb-0">80+</h4>
                                                <span> Team Member</span>
                                            </div>
                                        </li>
                                        <li className="pe-4 d-flex p-3 align-items-center">
                                            <div className="me-3">
                                                <img src="assets/img/icons/dw-i-4.svg" className="img-fluid" alt="icon" />
                                            </div>
                                            <div>
                                                <h4 className="mb-0">99%</h4>
                                                <span>Customer Satisfaction</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="digi-services pb-60">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="text-center mb-5">
                                <div>
                                    <span className="span-arrow">Best Digital Agency</span>
                                    <img src="assets/img/arro-right.svg" alt="arrow" />
                                </div>
                                <h2>
                                    We have extensive Experience in Social
                                    <span className="text-orange">Media Marketing</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">

                        <div className="col-lg-4 col-md-4 col-xl-4">
                            <div className="digi-service-single mb-4 mb-xl-0">
                                <h3 className="h5">Website Marketing</h3>
                                <p>
                                    A website is the centerpiece of all digital marketing activities. It is a very powerful channel on its own, but it’s also the medium needed to execute a variety of online marketing campaigns. A website should represent a brand, product, and service in a clear and memorable way. It should be fast, mobile-friendly, and easy to use.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-xl-4">
                            <div className="digi-service-single mb-4 mb-xl-0">
                                <h3 className="h5">Pay-Per-Click Advertising</h3>
                                <p>
                                    Pay-per-click advertising enables marketers to reach Internet users on a number of digital platforms through paid ads. Marketers can set up PPC campaigns on Google, Bing, LinkedIn, Twitter, Pinterest, or Facebook and show their ads to people searching for terms related to the products or services.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-xl-4">
                            <div className="digi-service-single mb-4 mb-xl-0">
                                <h3 className="h5">Content Marketing</h3>
                                <p>
                                    Globally promote long-term high-impact resources and goal-oriented growth strategies. Globally incubate
                                    extensible.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-xl-4">
                            <div className="digi-service-single mb-4 mb-xl-0">
                                <h3 className="h5">Email Marketing</h3>
                                <p>
                                    Email marketing is still one of the most effective digital marketing channels. Many people confuse email marketing with spam email messages, but that’s not what email marketing is all about. This type of marketing allows companies to get in touch with potential customers and anyone interested in their brands.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-xl-4">
                            <div className="digi-service-single mb-4 mb-xl-0">
                                <h3 className="h5">Social Media Marketing</h3>
                                <p>
                                    The primary goal of a social media marketing campaign is brand awareness and establishing social trust. As you go deeper into social media marketing, you can use it to get leads or even as a direct marketing or sales channel. Promoted posts and tweets are two examples of social media marketing.
                                </p>
                            </div>
                        </div>


                        <div className="col-lg-4 col-md-4 col-xl-4">
                            <div className="digi-service-single mb-4 mb-xl-0">
                                <h3 className="h5">Affiliate Marketing</h3>
                                <p>
                                    Affiliate marketing is one of the oldest forms of marketing, and the internet has brought new life to this old standby. With affiliate marketing, influencers promote other people’s products and get a commission every time a sale is made or a lead is introduced.
                                </p>
                            </div>
                        </div>


                        <div className="col-lg-4 col-md-4 col-xl-4">
                            <div className="digi-service-single mb-4 mb-xl-0">
                                <h3 className="h5">Video Marketing</h3>
                                <p>
                                    YouTube is one of the most popular search engines in the world. A lot of users are turning to YouTube before making a buying decision, to learn something, read a review, or just relax.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-xl-4">
                            <div className="digi-service-single mb-4 mb-xl-0">
                                <h3 className="h5">SMS Messaging</h3>
                                <p>
                                    Companies and nonprofit organizations also use SMS or text messages to send information about their latest promotions or give opportunities to willing customers. Political candidates running for office also use SMS message campaigns to spread positive information about their own platforms.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <section className="digtal-marketing-logo pb-60 pt-60">
                <div className="container">
                    <div className="bg-soft-blue py-5 px-4 rounded-3">
                        <div className="row justify-content-center">
                            <div className="col-auto">
                                <h5 className="mb-4">Trusted by the best companies in the world</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <div className="swiper digi-logo-slider">

                                    <Swiper
                                        // install Swiper modules
                                        modules={[Navigation]}
                                        spaceBetween={20}
                                        slidesPerView={4}
                                        navigation
                                        pagination={{ clickable: false }}
                                        scrollbar={{ draggable: true }}
                                        onSwiper={(swiper) => console.log(swiper)}
                                        onSlideChange={() => console.log('slide change')}
                                    >
                                        <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="single-logo">
                                                    <img src="assets/img/brand-logo/google.svg" className="img-fluid" height="30" alt="logo" />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="single-logo">
                                                    <img src="assets/img/brand-logo/facebook.svg" className="img-fluid" alt="logo" height="30" />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="single-logo">
                                                    <img src="assets/img/brand-logo/youtube.svg" className="img-fluid" alt="logo" height="30" />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="single-logo">
                                                    <img src="assets/img/brand-logo/printerest.svg" className="img-fluid" alt="logo" height="30" />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="single-logo">
                                                    <img src="assets/img/brand-logo/printerest.svg" className="img-fluid" alt="logo" height="30" />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="single-logo">
                                                    <img src="assets/img/brand-logo/webflow.svg" className="img-fluid" alt="logo" height="30" />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="single-logo">
                                                    <img src="assets/img/brand-logo/company.svg" className="img-fluid" alt="logo" height="30" />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="digi-integration bg-dark position-relative bg20">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-3">
                            <div className="int-icon-one position-relative">
                                <ul className="list-unstyled mb-0 d-none d-lg-block">
                                    <li>
                                        <img src="assets/img/digital-marketing/int-icon/fa.svg" alt="icon" />
                                    </li>
                                    <li>
                                        <img src="assets/img/digital-marketing/int-icon/fi.svg" alt="icon" />
                                    </li>
                                    <li>
                                        <img src="assets/img/digital-marketing/int-icon/slack.svg" alt="icon" />
                                    </li>
                                    <li>
                                        <img src="assets/img/digital-marketing/int-icon/inst.svg" alt="icon" />
                                    </li>
                                    <li>
                                        <img src="assets/img/digital-marketing/int-icon/er.svg" alt="icon" />
                                    </li>
                                    <li>
                                        <img src="assets/img/digital-marketing/int-icon/google.svg" alt="icon" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="text-center">
                                <div>
                                    <span className="span-arrow text-pink">Intergration Software </span>
                                    <img src="assets/img/arro-right.svg" alt="arrow" />
                                </div>
                                <h2 className="mb-4">Intergrated with 20+ Widely Used Software</h2>
                                <div>
                                    <Link to={contact} className="btn rounded-pill bg-orange me-3 mb-3">Get in touch</Link>
                                    <Link to={intigrations} className="btn rounded-pill btn-outline-light mb-3">Browse all integration</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="int-icon-two position-relative">
                                <ul className="list-unstyled mb-0 d-none d-lg-block">
                                    <li>
                                        <img src="assets/img/digital-marketing/int-icon/slash.svg" alt="icon" />
                                    </li>
                                    <li>
                                        <img src="assets/img/digital-marketing/int-icon/tree.svg" alt="icon" />
                                    </li>
                                    <li>
                                        <img src="assets/img/digital-marketing/int-icon/drive.svg" alt="icon" />
                                    </li>
                                    <li>
                                        <img src="assets/img/digital-marketing/int-icon/in.svg" alt="icon" />
                                    </li>
                                    <li>
                                        <img src="assets/img/digital-marketing/int-icon/star.svg" alt="icon" />
                                    </li>
                                    <li>
                                        <img src="assets/img/digital-marketing/int-icon/twi.svg" alt="icon" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}
export default DigitalMarketing
