import React,{useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {Link} from "react-router-dom";
import {contact, IntigrationLIst} from "../constants/helpers";
import IntigrationList from "../components/IntigrationList";
import {Helmet} from "react-helmet";
import {WebSerices} from "../constants/helpers";
const WebAppDevelopment = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    let data = WebSerices.find(el => el.id === "2");
    return(
        <>
            <Helmet>
                <title> {data.metaTitle}</title>
                <meta name="description" content={data.shortDesc}/>
                <meta name="keywords" content={data.metaKeyword}/>
                <meta name="author" content="Abdul Hanan"/>
            </Helmet>
            <section className="hero-section ptb-120 min-vh-100 d-flex align-items-center bg-dark text-white position-relative overflow-hidden bg12">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-6">
                            <div className="hero-content-wrap">
                                <h5 className="text-warning">#1 Software Company In World</h5>
                                <h1 className="fw-bold display-5">Advanced Feature to Grow Your Business</h1>
                                <p className="lead">We develop Best web Application to grow your business</p>
                                <div className="action-btns mt-5">
                                    <Link to={contact} className="btn btn-primary">Book an Appointment</Link>
                                    &nbsp;&nbsp;
                                    <Link to={contact} className="btn btn-outline-light">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-8 mt-5 mt-lg-0">
                            <div className="animated-img-wrap">
                                <ul className="animate-element parallax-element animated-hero-1">
                                    <li className="layer" data-depth="0.02">
                                        <img src="assets/img/screen/animated-screen-2.svg" alt="shape" className="img-fluid position-absolute type-0"/>
                                    </li>
                                </ul>
                                <img src="assets/img/screen/animated-screen-1.svg" alt="shape" className="position-relative img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work-process ptb-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-6">
                            <div className="section-heading text-center">
                                <h4 className="h5 text-primary">Process</h4>
                                <h2>We Follow Our Work Process</h2>
                                <p>To ensure a successful project development, we follow these work processes</p>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex align-items-center">
                        <div className="col-md-6 col-lg-3">
                            <div className="process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-3 mb-lg-0">
                                <div className="process-icon border border-light bg-custom-light rounded-custom p-3">
                                    <span className="h2 mb-0 text-primary fw-bold">1</span>
                                </div>
                                <h3 className="h5">Research</h3>
                            </div>
                        </div>
                        <div className="dots-line first"></div>
                        <div className="col-md-6 col-lg-3">
                            <div className="process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-3 mb-lg-0">
                                <div className="process-icon border border-light bg-custom-light rounded-custom p-3">
                                    <span className="h2 mb-0 text-primary fw-bold">2</span>
                                </div>
                                <h3 className="h5">Designing</h3>
                            </div>
                        </div>
                        <div className="dots-line first"></div>
                        <div className="col-md-6 col-lg-3">
                            <div className="process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-3 mb-lg-0 mb-md-0">
                                <div className="process-icon border border-light bg-custom-light rounded-custom p-3">
                                    <span className="h2 mb-0 text-primary fw-bold">3</span>
                                </div>
                                <h3 className="h5">Building</h3>
                            </div>
                        </div>
                        <div className="dots-line first"></div>
                        <div className="col-md-6 col-lg-3">
                            <div className="process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-0">
                                <div className="process-icon border border-light bg-custom-light rounded-custom p-3">
                                    <span className="h2 mb-0 text-primary fw-bold">4</span>
                                </div>
                                <h3 className="h5">Deliver</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="feature-tab-section ptb-120 bg-light">
                <div className="container">
                    <div className="row justify-content-center align-content-center">
                        <div className="col-md-10 col-lg-6">
                            <div className="section-heading text-center">
                                <h4 className="h5 text-primary">Features</h4>
                                <h2>The Scope of Our Web App Development Services</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <ul className="nav justify-content-center feature-tab-list-2 mb-0" id="nav-tab" role="tablist">
                                <li className="nav-item tabp">
                                    <a className="nav-link active" href="#tab-1" data-bs-toggle="tab" data-bs-target="#tab-1" role="tab" aria-selected="false">
                                        Web development project planning
                                    </a>
                                </li>
                                <li className="nav-item tabp">
                                    <a className="nav-link" href="#tab-2" data-bs-toggle="tab" data-bs-target="#tab-2" role="tab" aria-selected="false">
                                        UX and UI design
                                    </a>
                                </li>
                                <li className="nav-item tabp">
                                    <a className="nav-link" href="#tab-3" data-bs-toggle="tab" data-bs-target="#tab-3" role="tab" aria-selected="false">
                                        Web app development
                                    </a>
                                </li>
                                <li className="nav-item tabp">
                                    <a className="nav-link" href="#tab-4" data-bs-toggle="tab" data-bs-target="#tab-4" role="tab" aria-selected="false">
                                        Web app migration to the cloud
                                    </a>
                                </li>
                                <li className="nav-item tabp">
                                    <a className="nav-link" href="#tab-5" data-bs-toggle="tab" data-bs-target="#tab-5" role="tab" aria-selected="false">
                                        Quality assurance
                                    </a>
                                </li>
                                <li className="nav-item tabp">
                                    <a className="nav-link" href="#tab-6" data-bs-toggle="tab" data-bs-target="#tab-6" role="tab" aria-selected="false">
                                        Integration
                                    </a>
                                </li>
                                <li className="nav-item tabp">
                                    <a className="nav-link" href="#tab-7" data-bs-toggle="tab" data-bs-target="#tab-7" role="tab" aria-selected="false">
                                        24/7 support
                                    </a>
                                </li>
                                <li className="nav-item tabp">
                                    <a className="nav-link" href="#tab-8" data-bs-toggle="tab" data-bs-target="#tab-8" role="tab" aria-selected="false">
                                        Optimization and evolution
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content" id="nav-tabContent">

                                <div className="tab-pane fade pt-60 active show" id="tab-1" role="tabpanel">
                                    <div className="row justify-content-center align-items-center justify-content-around">
                                        <div className="col-lg-5">
                                            <div className="feature-tab-info">
                                                <h3>Web development project planning</h3>
                                                <p>
                                                    Drawing on the experience in over <strong>30 industries</strong>, our business analysts define project
                                                    requirements and outline the scope of a future web app. After that, our project managers provide a high-level project plan,
                                                    time and budget estimates, and compile a team for your web project implementation.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                            <img src="assets/img/screen/widget-12.png" alt="feature tab" className="img-fluid mt-4 mt-lg-0 mt-xl-0"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade pt-60 " id="tab-2" role="tabpanel">
                                    <div className="row justify-content-center align-items-center justify-content-around">
                                        <div className="col-lg-5">
                                            <div className="feature-tab-info">
                                                <h3>UX and UI design</h3>
                                                <p>
                                                    Answering the specific needs of your target audience, Xampera UX and UI experts create <strong>intuitive navigation</strong>
                                                    and <strong>appealing interfaces</strong> for your web software. Our responsive web app design guarantees smooth digital experience across all devices,
                                                    thus leading to <srong>higher user satisfaction</srong> and <strong>increased conversion rate</strong>.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                            <img src="assets/img/screen/widget-12.png" alt="feature tab" className="img-fluid mt-4 mt-lg-0 mt-xl-0"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade pt-60" id="tab-3" role="tabpanel">
                                    <div className="row justify-content-center align-items-center justify-content-around">
                                        <div className="col-lg-5">
                                            <div className="feature-tab-info">
                                                <h3>Web app development</h3>
                                                <p>
                                                    xampera web architects are experienced in designing all latest architecture patterns, including <strong>microservices</strong>, <strong>headless</strong> and <strong>PWA*</strong>,
                                                    while our web app developers are skilled in taming a wide variety of <strong>web development tools</strong>.
                                                    To ensure on-time and quality delivery, we leverage our project management office competencies and use a rich set of KPIs, <srtong>such as :</srtong>
                                                    <strong>Lead Time/Cycle Time</strong>,
                                                    <strong>Release Burndown</strong>,
                                                    <strong>Code Churn</strong>,
                                                    <strong>Mean Time to Recover (MTTR)</strong>,
                                                    <strong>Escaped Defects, etc</strong>.
                                                </p>

                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                            <img src="assets/img/screen/widget-12.png" alt="feature tab" className="img-fluid mt-4 mt-lg-0 mt-xl-0"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade pt-60" id="tab-4" role="tabpanel">
                                    <div className="row justify-content-center align-items-center justify-content-around">
                                        <div className="col-lg-5">
                                            <div className="feature-tab-info">
                                                <h3>Web app migration to the cloud</h3>
                                                <p>
                                                    Our cloud experts build pragmatic cloud migration strategies and execute fast and smooth migrations without unplanned disruptions to your business.
                                                </p>

                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                            <img src="assets/img/screen/widget-12.png" alt="feature tab" className="img-fluid mt-4 mt-lg-0 mt-xl-0"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade pt-60" id="tab-5" role="tabpanel">
                                    <div className="row justify-content-center align-items-center justify-content-around">
                                        <div className="col-lg-5">
                                            <div className="feature-tab-info">
                                                <h3>Quality assurance</h3>
                                                <p>
                                                    Xampera uses the <strong>sophisticated</strong> quality management system approved by the <strong>ISO 9001</strong>
                                                    certificate and relies on the Integrated Manual and Automated Testing
                                                    (IMAAT) approach to confirm that your web app’s performance will be <strong>secure</strong>, <dtrong>sustainable</dtrong>, and <strong>fast</strong>.
                                                </p>

                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                            <img src="assets/img/screen/widget-12.png" alt="feature tab" className="img-fluid mt-4 mt-lg-0 mt-xl-0"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade pt-60" id="tab-6" role="tabpanel">
                                    <div className="row justify-content-center align-items-center justify-content-around">
                                        <div className="col-lg-5">
                                            <div className="feature-tab-info">
                                                <h3>Integration</h3>
                                                <p>
                                                    To help you automate and streamline workflows, we achieve <strong>seamless integration</strong> of your web app/portal with a CMS and your software infrastructure via custom APIs.
                                                    When adding new functional modules to already developed web solutions,
                                                    our integration experts use the CI/CD pipeline to allow for smooth deployment <strong>without impeding the work of your web app</strong>.
                                                </p>

                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                            <img src="assets/img/screen/widget-12.png" alt="feature tab" className="img-fluid mt-4 mt-lg-0 mt-xl-0"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade pt-60" id="tab-7" role="tabpanel">
                                    <div className="row justify-content-center align-items-center justify-content-around">
                                        <div className="col-lg-5">
                                            <div className="feature-tab-info">
                                                <h3>24/7 support</h3>
                                                <p>
                                                    Providing L1, L2 and L3 support levels, we troubleshoot your web app and resolve any emerging issues in usage, configurations or code. Our support ensures high availability and failsafe functionality of your web app,
                                                    web portal, or another kind of a web-based solution, making for <strong>stability of workflows</strong> and <strong>high user satisfaction</strong>.
                                                </p>

                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                            <img src="assets/img/screen/widget-12.png" alt="feature tab" className="img-fluid mt-4 mt-lg-0 mt-xl-0"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade pt-60" id="tab-8" role="tabpanel">
                                    <div className="row justify-content-center align-items-center justify-content-around">
                                        <div className="col-lg-5">
                                            <div className="feature-tab-info">
                                                <h3>Optimization and evolution</h3>
                                                <p>
                                                    To keep in sync with your business needs and user expectations, we <strong>continuously optimize</strong> and <strong>enhance your web app</strong>,
                                                    introducing new relevant functionality.
                                                </p>

                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                            <img src="assets/img/screen/widget-12.png" alt="feature tab" className="img-fluid mt-4 mt-lg-0 mt-xl-0"/>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="promo-section ptb-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="section-heading text-center">
                                <h2>Types of Apps We’ve Already Delivered</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="promo-single position-relative text-center bg-white custom-shadow rounded-custom p-5 mb-4">
                                <div className="promo-icon mb-32">
                                    <img src="assets/img/enterprise-apps.svg" />
                                </div>
                                <div className="promo-info">
                                    <h3 className="h5">Enterprise Web Apps</h3>
                                </div>

                                <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                    <img src="assets/img/shape/dot-big-square.svg" alt="shape"/>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="promo-single position-relative text-center bg-white custom-shadow rounded-custom p-5 mb-4">
                                <div className="promo-icon mb-32">
                                    <img src="assets/img/web-portal.svg" />
                                </div>
                                <div className="promo-info">
                                    <h3 className="h5">Web Portals</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="promo-single position-relative text-center bg-white custom-shadow rounded-custom p-5 mb-4">
                                <div className="promo-icon mb-32">
                                    <img src="assets/img/customer-blue.svg" />
                                </div>
                                <div className="promo-info">
                                    <h3 className="h5">Customer-facing Apps</h3>
                                </div>

                                <div className="dot-shape-bg position-absolute z--1 right--40 bottom--40">
                                    <img src="assets/img/shape/dot-big-square.svg" alt="shape"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="promo-single position-relative text-center bg-white custom-shadow rounded-custom p-5 mb-4">
                                <div className="promo-icon mb-32">
                                    <img src="assets/img/inventory-management-1.svg" />
                                </div>
                                <div className="promo-info">
                                    <h3 className="h5">Supply Chain Management</h3>
                                </div>

                                <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                    <img src="assets/img/shape/dot-big-square.svg" alt="shape"/>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="promo-single position-relative text-center bg-white custom-shadow rounded-custom p-5 mb-4">
                                <div className="promo-icon mb-32">
                                    <img src="assets/img/in-app-shopping-orange.svg" />
                                </div>
                                <div className="promo-info">
                                    <h3 className="h5">Ecommerce</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="promo-single position-relative text-center bg-white custom-shadow rounded-custom p-5 mb-4">
                                <div className="promo-icon mb-32">
                                    <img src="assets/img/healthcare-blue.svg" />
                                </div>
                                <div className="promo-info">
                                    <h3 className="h5">Web Apps for Healthcare</h3>
                                </div>

                                <div className="dot-shape-bg position-absolute z--1 right--40 bottom--40">
                                    <img src="assets/img/shape/dot-big-square.svg" alt="shape"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="feature-section-two ptb-120">
                <div className="container">
                    <div className="row align-items-center justify-content-lg-between justify-content-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="feature-content-wrap">
                                <div className="section-heading">
                                    <h2>We are development experts on all technologies</h2>
                                    <p>Quickly incubate functional channels with multidisciplinary architectures authoritatively
                                        fabricate formulate exceptional innovation.</p>
                                </div>
                                <ul className="list-unstyled mb-0">
                                    <li className="d-flex align-items-start mb-4">
                                        <div className="icon-box bg-primary rounded me-4">
                                            <i className="fas fa-bezier-curve text-white"></i>
                                        </div>
                                        <div className="icon-content">
                                            <h3 className="h5">Pixel Perfect Design</h3>
                                            <p>
                                                The goal of pixel perfect design is to create a visual design that appears flawless and visually precise, with every element precisely placed and aligned to a specific grid. This approach is often used in web design, graphic design, and user interface design, where precise placement and alignment of elements can have a significant impact on the overall look and feel of a design.
                                            </p>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-start mb-4">
                                        <div className="icon-box bg-danger rounded me-4">
                                            <i className="fas fa-fingerprint text-white"></i>
                                        </div>
                                        <div className="icon-content">
                                            <h3 className="h5">Unique &amp; Minimal Design</h3>
                                            <p> In this approach, designers strive to eliminate unnecessary elements and use a minimal color palette and clean, simple lines to create a clean and uncluttered design. The focus is on creating a design that is visually impactful while being simple and easy to understand.
                                            </p>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-start mb-4 mb-lg-0">
                                        <div className="icon-box bg-dark rounded me-4">
                                            <i className="fas fa-headset text-white"></i>
                                        </div>
                                        <div className="icon-content">
                                            <h3 className="h5">24/7 Free Online Support</h3>
                                            <p>
                                                We provide 24/7 Free Online Support channels such as live chat, email, phone, or social media. The goal of 24/7 support is to provide customers with quick and convenient assistance whenever they need it, which can help improve customer satisfaction and loyalty.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-7">
                            <div className="feature-img-wrap">
                                <img src="assets/img/feature-hero-img.svg" alt="feature image" className="img-fluid rounded-custom"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="why-choose-us pt-60 pb-120">
                <div className="container">
                    <div className="row justify-content-lg-between justify-content-center align-items-center">
                        <div className="col-lg-5 col-md-7 order-1 order-lg-0">
                            <div className="why-choose-img position-relative">
                                <img src="assets/img/feature-hero-img-2.svg" className="img-fluid" alt="duel-phone"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 order-0 order-lg-1">
                            <div className="why-choose-content">
                                <div className="mb-32">
                                    <h2>We run all kinds of services in form of software</h2>
                                    <p>Conceptualize multimedia based ROI and wireless intellectual capital. Dramatically seize
                                        visionary technologies whereas interactive value. </p>
                                </div>
                                <ul className="list-unstyled d-flex flex-wrap list-two-col">
                                    <li className="py-1"><i className="fas fa-check-circle me-2 text-primary"></i>Thought leadership</li>
                                    <li className="py-1"><i className="fas fa-check-circle me-2 text-primary"></i>Personal branding</li>
                                    <li className="py-1"><i className="fas fa-check-circle me-2 text-primary"></i>Modernized prospecting</li>
                                    <li className="py-1"><i className="fas fa-check-circle me-2 text-primary"></i>Better win rates</li>
                                    <li className="py-1"><i className="fas fa-check-circle me-2 text-primary"></i>Showcasing success</li>
                                    <li className="py-1"><i className="fas fa-check-circle me-2 text-primary"></i>Sales compliance</li>
                                    <li className="py-1"><i className="fas fa-check-circle me-2 text-primary"></i>Manage smartly</li>
                                    <li className="py-1"><i className="fas fa-check-circle me-2 text-primary"></i>Communicate fast</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cta-section bg-dark text-white ptb-120 position-relative overflow-hidden">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-5 col-md-12">
                            <div className="position-relative z-5">
                                <h2>Actionable Reports to Grow your Business</h2>
                                <p>Conveniently syndicate client-centric users for transparent scenarios. Uniquely evolve
                                    partnerships rather than cross-platform interfaces.</p>
                                <Link to={contact} className="btn btn-primary mt-4">Get Start Now</Link>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="row align-items-center justify-content-center position-relative z-2">
                                <div className="col-md-6">
                                    <div className="cta-card rounded-custom text-center shadow p-5 bg-white my-4">
                                        <h3 className="display-5 fw-bold">9/10</h3>
                                        <p className="mb-0">Overall our customer satisfaction score</p>
                                    </div>
                                    <div className="cta-card rounded-custom text-center shadow p-5 bg-white my-4">
                                        <h3 className="display-5 fw-bold">15K+</h3>
                                        <p className="mb-0">Happy customer in worldwide</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="cta-card rounded-custom text-center shadow p-5 bg-white">
                                        <h3 className="display-5 fw-bold">95%</h3>
                                        <p className="mb-0">Our Customer customer satisfaction score</p>
                                    </div>
                                </div>
                                <div className="bg-circle rounded-circle position-absolute z--1">
                                    <img src="assets/img/shape/blob.svg" alt="feature image" className="img-fluid rounded"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light left-30"></div>
                    <div className="bg-circle rounded-circle circle-shape-1 position-absolute bg-warning left-5"></div>
                </div>
            </section>
            <IntigrationList/>
        </>
    )
}
export default WebAppDevelopment
