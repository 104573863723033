import React,{useEffect} from "react";
import {IntigrationLIst} from '../constants/helpers';
import {Helmet} from "react-helmet";
import {WebSerices} from "../constants/helpers";
const Intigrations = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    let data = WebSerices.find(el => el.id === "6");
    return(
        <>
            <Helmet>
                <title> {data.metaTitle}</title>
                <meta name="description" content={data.shortDesc}/>
                <meta name="keywords" content={data.metaKeyword}/>
                <meta name="author" content="Abdul Hanan"/>
            </Helmet>
            <section className="page-header position-relative overflow-hidden ptb-120 bg-dark bg15">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <h1 className="display-5 fw-bold">Integrations</h1>
                            <p className="lead">We Integrate your Application / Website With those API</p>
                        </div>
                    </div>
                    <div className="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light right-5"></div>
                </div>
            </section>

            <section className="integration-section bg-light ptb-120">
                <div className="container">
                    <div className="row align-items-center justify-content-lg-between">
                        <div className="col-lg-6 col-md-12">
                            <div className="section-heading">
                                <h5 className="h6 text-primary">Integration</h5>
                                <h2>Integration with Most Powerful Platforms</h2>
                                <p>
                                Dynamically pursue reliablet convergence rather than 24/7 process improvements develop end-to-end customer service impactful action items and web-enabled markets.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="section-heading">
                                <p>
                                Third-party integration refers to the process of connecting and integrating software or services developed by an external party with an existing software application or system. This integration allows for the sharing of data, functionality, or resources between the two systems, enabling them to work together seamlessly. Examples of third-party integrations include payment gateways, social media platforms, and customer relationship management (CRM) systems.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="integration-wrapper position-relative w-100">
                                <ul className="integration-list list-unstyled mt-5">
                                    {IntigrationLIst.map((data,index)=>{
                                        return(
                                            <li key={index+data.id}>
                                                <div className="single-integration">
                                                    <img src={data.logo} alt="integration"
                                                         className="img-fluid"/>
                                                    <h6 className="mb-0 mt-4">{data.name}</h6>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Intigrations
