import React, {useEffect, useState} from "react";
import axios from "axios";
import {Helmet} from "react-helmet";
const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [formData,setFormData] = useState({
        name:"",
        email:"",
        phone:"",
        subject:"",
        message:""
    })
    const [res,setRes] = useState(null)
    const InputHandler = (e) =>{
        const name = e.target.name;
        const value = e.target.value;
        setFormData(prevState =>({...prevState,[name]:value}))
    }
    const sub = async (e) =>{
        e.preventDefault();
        await axios.post(`https://xampera.com/backend/contactPro.php`,formData,{
            headers: {"Content-type": "application/json"}
    }).then(response => {
            setRes(response.data);
            e.target.reset();
        }).catch(err => {
            if (err) {
                setRes(err.response.data)
            }
        });

    }
    return(
        <>
            <Helmet>
                <title>Contact Us | Xampera One Stop IT Solutions</title>
                <meta name="description" content="We Care Your any IT SolutionMake your work easier with an integrated ecosystem that lets all departments work properly together."/>
                <meta name="keywords" content="Website Development, Web Application Development, Android App Development"/>
                <meta name="author" content="Abdul Hanan"/>
            </Helmet>
            <section className="page-header position-relative overflow-hidden ptb-120 bg-dark bg10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <h1 className="display-5 fw-bold">Contact Us</h1>
                            <p className="lead">
                                Feel Free To Contact Us we Are Here For You 24/7. Start New Projects With us
                            </p>
                        </div>
                    </div>
                    <div className="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light right-5"></div>
                </div>
            </section>
            <section className="contact-promo ptb-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 mt-4 mt-lg-0">
                            <div className="contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100">
                                <span className="fad fa-comment-alt-lines fa-3x text-primary"></span>
                                <div className="contact-promo-info mb-4">
                                    <h5>Chat with us</h5>
                                    <p>We've got live Social Experts waiting to help you <strong> Monday to Saturday</strong> from
                                        <strong> 9am to 5pm EST.</strong>
                                    </p>
                                </div>
                                <a href="mailto:sales@xampera.com" className="btn btn-link mt-auto">Chat with us</a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-4 mt-lg-0">
                            <div className="contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100">
                                <span className="fad fa-envelope fa-3x text-primary"></span>
                                <div className="contact-promo-info mb-4">
                                    <h5>Email Us</h5>
                                    <p>Simple drop us an email at <strong> sales@xampera.com </strong> and you'll receive a reply within 24 hours</p>
                                </div>
                                <a href="mailto:sales@xampera.com" className="btn btn-primary mt-auto">Email Us</a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-4 mt-lg-0">
                            <div className="contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100">
                                <span className="fad fa-phone fa-3x text-primary"></span>
                                <div className="contact-promo-info mb-4">
                                    <h5>Give us a call</h5>
                                    <p>Give us a ring.Our Experts are standing by <strong>monday to Saturday</strong> from 
                                        <strong> 9am to 5pm EST.</strong>
                                    </p>
                                </div>
                                <a href="tel:+12697561071" className="btn btn-link mt-auto">+923075830000,&nbsp;+923075840000</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact-us-form pt-60 pb-120 bg9">
                <div className="container">
                    <div className="row justify-content-lg-between align-items-center">
                        <div className="col-lg-6 col-md-8">
                            <div className="section-heading">
                                <h2>Talk to Our Sales & Marketing Department Team</h2>
                                <p>Collaboratively promote client-focused convergence vis-a-vis customer directed alignments via
                                    standardized infrastructures.</p>
                            </div>
                            <form onSubmit={sub} className="register-form">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <label for="firstName" className="mb-1">First name <span
                                            className="text-danger">*</span></label>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" onChange={InputHandler} id="firstName" name="name" required placeholder="Full Name" aria-label="First name"/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <label for="phone" className="mb-1">Phone <span className="text-danger">*</span></label>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" onChange={InputHandler} id="phone" name="phone" required placeholder="Phone" aria-label="Phone"/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <label for="email" className="mb-1">Email<span className="text-danger">*</span></label>
                                        <div className="input-group mb-3">
                                            <input type="email" className="form-control" onChange={InputHandler} name="email" id="email" required placeholder="Email" aria-label="Email"/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="email" className="mb-1">Subject<span
                                            className="text-danger">*</span></label>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" name="subject" onChange={InputHandler} id="subject"
                                                   required placeholder="Subject" aria-label="Subject"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label for="yourMessage" className="mb-1">Message <span className="text-danger">*</span></label>
                                        <div className="input-group mb-3">
                                            <textarea className="form-control" id="yourMessage" onChange={InputHandler} name="message" required placeholder="How can we help you?" style={{height: "120px"}}></textarea>
                                        </div>
                                    </div>
                                </div>
                                {res !== null &&
                                    <div className="btn btn-outline-primary">{res}</div>
                                }
                                {res === null &&
                                    <button type="submit" className="btn btn-primary mt-4">Get in Touch</button>
                                }


                            </form>
                        </div>
                        <div className="col-lg-5 col-md-10">
                            <div className="contact-us-img">
                                <img src="assets/img/ContactUs.jpg" alt="contact us" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact
