import React from "react";
import {Link} from "react-router-dom";
import {WebSerices,Technologies,DigitalServices,home,about,contact} from "../constants/helpers";
import $ from 'jquery';
const Header = () => {
    const close = () => {
        const element = document.getElementById("offcanvasWithBackdrop");
    }
    return (
        <>
            <link rel= "canonical" href= "https://xampera.com/" />
            <header className="main-header position-inherit w-100 " id={'dad'}>
                <nav className="navbar navbar-expand-xl navbar-light sticky-header">
                    <div className="container d-flex align-items-center justify-content-lg-between position-relative">
                        <Link to={home}
                              className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none">
                            <img src="assets/img/logoo.png" alt="logo" className="img-fluid logo-white"/>
                            <img src="assets/img/logoo.png" alt="logo" className="img-fluid logo-color"/>
                        </Link>

                        <a className="navbar-toggler position-absolute right-0 border-0" href="#offcanvasWithBackdrop"
                           role="button">
                        <span
                            className="far fa-bars"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasWithBackdrop"
                            aria-controls="offcanvasWithBackdrop"
                        ></span>
                        </a>
                        <div className="clearfix"></div>
                        <div className="collapse navbar-collapse justify-content-center">
                            <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                                <li><Link to={home} className="nav-link">Home</Link></li>
                                <li><Link to={about} className="nav-link">About</Link></li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown"
                                       aria-expanded="false">Services</Link>
                                    <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                                        <div className="dropdown-grid rounded-custom width-full">
                                            <div className="dropdown-grid-item">
                                                <h6 className="drop-heading">Web & Mobile</h6>
                                                {WebSerices.map((data,index)=>{
                                                    return(
                                                        <Link to={data.link} key={index+data.id} className="dropdown-link px-0">
                                                            <span className="me-2"><img src={data.logo} width={"25px"} /></span>
                                                            <div className="drop-title">{data.name}</div>
                                                        </Link>
                                                    )
                                                })}
                                            </div>
                                            <div className="dropdown-grid-item last-item radius-right-side">
                                                <h6 className="drop-heading">Digital Services</h6>
                                                {DigitalServices.map((data,index)=>{
                                                    return(
                                                        <Link to={data.link} key={index+data.id} className="dropdown-link px-0">
                                                            <span className="me-2"><img src={data.logo} width={"25px"} /></span>
                                                            <div className="drop-title">{data.name}</div>
                                                        </Link>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown"
                                       aria-expanded="false">Technologies</Link>
                                    <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                                        <div className="dropdown-grid rounded-custom width-full">
                                            <div className="dropdown-grid-item">
                                                <h6 className="drop-heading">We are working on </h6>
                                                {Technologies.map((data,index)=>{
                                                    if(index < 7){
                                                        return(
                                                            <Link key={index+data.id} className="dropdown-link px-0">
                                                                <span className="me-2"><img src={data.logo} width={"25px"} /></span>
                                                                <div className="drop-title">{data.name}</div>
                                                            </Link>
                                                        )
                                                    }
                                                })}
                                            </div>
                                            <div className="dropdown-grid-item last-item radius-right-side">
                                                <h6 className="drop-heading">&nbsp;</h6>
                                                {Technologies.map((data,index)=>{
                                                    if(index > 6){
                                                        return(
                                                            <Link key={index+data.id} className="dropdown-link px-0">
                                                                <span className="me-2"><img src={data.logo} width={"25px"} /></span>
                                                                <div className="drop-title">{data.name}</div>
                                                            </Link>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li><Link to={contact} className="nav-link">Contact Us</Link></li>

                            </ul>
                        </div>

                        <div className="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block">
                            <a aria-label="Chat on WhatsApp" target={"_blank"} href="https://wa.me/+12697561071?text=I'm%20interested%20to%20schedule%20a%20meeting%20with%20you"> <img alt="Chat on WhatsApp" src="assets/WhatsAppButtonGreenLarge.svg" /></a>
                            &nbsp; 
                            &nbsp; 
                            <Link to={contact} className="btn btn-primary"
                            >Get a Quote</Link>
                        </div>
                    </div>
                </nav>

                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasWithBackdrop">
                    <div className="offcanvas-header d-flex align-items-center mt-4">
                        <Link to={home} className="d-flex align-items-center mb-md-0 text-decoration-none">
                            <img src="assets/img/logoo.png" alt="logo" className="img-fluid ps-2"/>
                        </Link>
                        <button type="button" className="close-btn text-danger" data-bs-dismiss="offcanvas"
                                aria-label="Close">
                            <i className="far fa-close"></i>
                        </button>
                    </div>
                    <div className="offcanvas-body" onClick={close}>
                        <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                            <li><Link to={home}  className="nav-link">Home</Link></li>
                            <li><Link to={about} className="nav-link">About</Link></li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Services
                                </a>
                                <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                                    <div className="dropdown-grid rounded-custom width-half">
                                        <h6 className="drop-heading pmt">For Web</h6>
                                        {WebSerices.map((data,index)=>{
                                            return(
                                            <div key={index+data.id} className="dropdown-grid-item">

                                                <Link to={data.link}  className="dropdown-link">
                                                    <span className="demo-list bg-primary rounded text-white fw-bold">{data.id}</span>
                                                    <div className="dropdown-info">
                                                        <div className="drop-title">{data.name}</div>
                                                    </div>
                                                </Link>
                                            </div>
                                            )
                                        })}
                                        <h6 className="drop-heading pmt">Digital Services</h6>
                                        {DigitalServices.map((data,index)=>{
                                            return(
                                                <div key={index+data.id} className="dropdown-grid-item">
                                                    <Link to={data.link}  className="dropdown-link">
                                                        <span className="demo-list bg-primary rounded text-white fw-bold">{data.id}</span>
                                                        <div className="dropdown-info">
                                                            <div className="drop-title">{data.name}</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                            </li>

                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                   aria-expanded="false">Technologies</Link>
                                <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                                    <div className="dropdown-grid rounded-custom width-full-3">
                                        <div className="dropdown-grid-item">
                                            {Technologies.map((data,index)=>{
                                                return(
                                                    <Link to={data.link} key={index+data.id} className="dropdown-link px-0">
                                                        <span className="me-2"><i className={data.icon}></i></span>
                                                        <div className="drop-title">{data.name}</div>
                                                    </Link>
                                                )
                                            })}
                                        </div>
                                        <div className="dropdown-grid-item last-item bg-light radius-right-side">
                                            <a href="#"><img src="assets/img/feature-img3.jpg" alt="add" className="img-fluid rounded-custom"/></a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li><a href="pricing.html" className="nav-link">Contact Us</a></li>
                        </ul>
                    </div>
                </div>

            </header>
        </>
    )
}
export default Header
