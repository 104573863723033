import React from "react";
import {Link} from "react-router-dom";
import {allServices} from "../constants/helpers";

const IntigrationDetail = () => {
    return(
        <>
            <section className="crm-integration bg-white pt-120 pb-80">
                <div className="container">
                    <div className="row justify-content-between align-items-center g-4">
                        <div className="col-lg-6">
                            <div className="crm-appss-circle">
                                <img src="assets/img/crm/apps.png" alt="apps" className="img-fluid"/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="crm-integration-content">
                                <div className="crm-title">
                                    <span className="crm-subtitle">Integration Software <img
                                        src="assets/img/shape/arrow-red.png" alt="arrow" className="ms-1"/></span>
                                    <h2 className="mt-2 mb-3">Integrated with Xampera <br/> Used Software</h2>
                                    <p>
                                        We combining multiple software applications or systems to work together as a single, seamless solution. This can involve exchanging data and information between the systems, or using APIs and other technology to coordinate their activities. The goal of integration is to improve efficiency, streamline workflows, and eliminate the need for manual data transfer, thereby reducing the risk of errors and increasing productivity.
                                    </p>
                                </div>
                                <Link to={allServices} className="btn crm-secondary-btn mt-4">Browse All Service</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default IntigrationDetail
