import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
const TermsConditions = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Helmet>
                <title>Terms & Conditions</title>
                <meta name="description" content="We Care Your any IT SolutionMake your work easier with an integrated ecosystem that lets all departments work properly together." />
                <meta name="keywords" content="Website Development, Web Application Development, Android App Development" />
                <meta name="author" content="Abdul Hanan" />
            </Helmet>
            <section className="page-header position-relative overflow-hidden ptb-120 bg-dark bg10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <h1 className="display-5 fw-bold">Terms and Condition</h1>
                        </div>
                    </div>
                    <div className="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light right-5"></div>
                </div>
            </section>
            <section className="contact-us-form pt-60 pb-120 bg9">
                <div className="container">
                    <div className="row justify-content-lg-between align-items-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="section-heading">
                                <p>
                                    This privacy policy covers XAMPERA's handling of information, data, and any assets collected by XAMPERA or provided to XAMPERA at the sole discretion of its clients. <br/>
                                    This privacy policy does not apply to the practices of companies and third parties that XAMPERA does not own or control, or of persons that XAMPERA does not employ or manage, including any third-party content contributors bound by the contract.<br/>
                                    “Information” where used means any information voluntarily provided by our clients through any means for carrying out the work as per the business contract. XAMPERA may at any time, without prior notice and in its sole discretion, amend this policy. Please keep the latest version of this policy for your reference at all times.<br/>
                                    This privacy policy aims to comply with the privacy laws applicable to the personal data we collect and use as part of engagements with our customers and clients.<br/>
                                    <br/><strong>Information Collection and Use</strong> <br/>
                                    XAMPERA values the privacy of its customers and clients. The policy of XAMPERA and its worldwide offices, hereinafter called “XAMPERA“ regarding the collection, storage, and processing of information is elaborated in this policy. XAMPERA reserves the right to occasionally change the policy at its discretion.<br/>
                                    <br/><strong>Information Collection</strong> <br/>
                                    All customer/client information collected shall be used for the purpose it was meant to serve as per the agreement. Customer information which includes but is not limited to NPI, PII, etc. shall be safeguarded using adequate controls and security measures (such as username and password), shared between XAMPERA and its client. <br/>
                                    XAMPERA collects information such as name, email, and shipping address and financial information such as credit card number, expiration date, etc. over the web using “order form” from clients/customers ordering our products, for billing purposes and to fill customer orders. If we have trouble processing an order, the information is used to contact the clients/customers.<br/>
                                    <br/><strong>Information Use</strong> <br/>
                                    XAMPERA shall use information collected as part of engagement.<br/>
                                    1) To provide clients/customers with information about product upgrades, new products, services, newsletters, informative emails, and research on future product ideas or improvements. <br/>
                                    2) To assist XAMPERA in creating content that is relevant to clients/customers. <br/>
                                    3) To assist XAMPERA in creating better products and services to meet clients'/customer’s needs. <br/>
                                    4) To allow clients/customers to purchase, download, and manage product licenses, obtain access to services, or otherwise engage in activities selected by clients/customers.<br/>
                                    5) To help XAMPERA share software, services, or product information important to its clients/customers <br/>
                                    6) Cookies – A cookie is a piece of data stored on the user’s computer tied to information about the user. Usage of a cookie is in no way linked to any personally identifiable information while on our site. We use session ID cookies. For the session ID cookie, once users close the browser, the cookie simply terminates.<br/>
                                    7) Cookies are used to maintain the identity of users with our site after users log in with a username and password. This allows the users to identify themselves (log in) once rather than multiple times per web session. You can choose to disable cookies by following the procedure suggested by your particular browser. If you choose to disable cookies in your browser, you may still be able to access our site depending on where you are on our site.<br/>
                                    
                                    This information shall be compiled and analyzed to enhance our products and services and shall not be used in any way used to identify our clients/customers personally.<br/>
                                    
                                    Except as specifically described in this privacy policy, we will not, without clients'/customers' prior consent, sell any information such as non-Public Information, Personally Identifiable Information to any third parties other than authorized third parties and others involved in the e-commerce distribution chain (e.g., vendors, suppliers), which may need access to some of your personal information. For example, if we ship an order to you, we must share your name and address with a shipping company. We do not give access to data on individual transactions to third parties, other than as described in this privacy policy.<br/>
                                    <br/><strong>Communications of Information</strong> <br/>
                                    We at XAMPERA shall ensure to preserve user privacy, however, there are certain circumstances where XAMPERA may need to disclose personal information when required by law wherein we have a good-faith belief that such action is necessary to comply with a current judicial proceeding, a court order or legal process served on our products, services or website.<br/>
                                    XAMPERA uses links to other sites in its websites offered as part of services to different clients. Please be aware that XAMPERA shall not be responsible for the privacy practices of such other sites. We encourage clients/customers to be aware that once redirected to external sites either voluntarily or involuntarily, and read the privacy statements of every Web site that collects personally identifiable information. This privacy statement applies solely to information collected by XAMPERA as part of its engagement of products or services or websites with its customers and clients.<br/>
                                    Whenever XAMPERA collects sensitive information such as credit card numbers and billing information, such information is encrypted and protected with industry-standard encryption technology. To prevent unauthorized access, maintain data accuracy, and ensure proper use of information, XAMPERA has put in place appropriate physical, electronic, and managerial procedures to safeguard and secure the information we collect in our applications. All of our users’ information, not just the sensitive information mentioned above, is restricted in our offices.<br/>
                                    XAMPERA keeping in view the privacy of personal information received from minors/children (individuals under the age of 18), shall not knowingly receive/collect personal information at company portals and websites. If XAMPERA comes to know that there is personal information received from a minor is available at company portals and websites, it shall be deleted.<br/>
                                    In case a customer or clients personally identifiable information changes (such as zip code, phone, email, or postal address), or if a client no longer continues to use the service, XAMPERA shall correct, update or delete users’ personally identifiable information.<br/>
                                    XAMPERA ensures to notify changes to its clients in its privacy policy, all such changes are posted as part of this privacy statement, on our website and other places we deem appropriate so our users, customers, and clients are always aware of what information we collect, how we use it, and under what circumstances if any, we disclose it.<br/>
                                    If, however, we are going to utilize our users’ PII/NPI in a manner different from that stated at the time of collection we will notify users by posting a notice for change on our Web site for 30 days.<br/>
                                    In the event any of the provisions of this privacy policy are held to be unenforceable, the remaining provisions will be unimpaired and the unenforceable provision(s) will be replaced by such enforceable provision(s) as comes closest to the intention underlying the unenforceable provision(s).<br/>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TermsConditions