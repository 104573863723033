import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";
import {
    uIUXDDesigning,
    mobileAppDevelopment,
    webAppDevelopment,
    WebSerices,
    DigitalServices,
    intigrations, contact, about
} from "../constants/helpers";
import { Helmet } from "react-helmet";
import ContactSupport from "../components/ContactSupport";
const Home = () => {
    useEffect(() => {
        AOS.init();
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Helmet>
                <title>Home | Xampera One Stop IT Solutions</title>
                <meta name="description" content="We Care Your any IT SolutionMake your work easier with an integrated ecosystem that lets all departments work properly together. Xampera is the technology partner of choice for some of the world's leading enterprises, startups, and SMEs." />
                <meta name="keywords" content="Website Development, Web Application Development, Android App Development" />
                <meta name="author" content="Abdul Hanan" />
            </Helmet>
            <section className="hero-it-solution hero-nine-bg ptb-120 bg1">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="hero-content-wrap mt-5 mt-lg-0 mt-xl-0">
                                <h1 className="fw-bold display-5">We Care Your any IT Solution</h1>
                                <p className="lead">
                                    Make your work easier with an integrated ecosystem that lets
                                    all departments work properly together.
                                </p>
                                <div
                                    className="action-btn mt-5 align-items-center d-block d-sm-flex d-lg-flex d-md-flex">
                                    <Link to={contact} className="btn btn-primary me-3">Request For Demo</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="hero-img position-relative mt-5 mt-lg-0">
                                <img src="assets/img/banner_image1.png" alt="hero hero-it-solution "
                                    className="img-fluid" />
                                <div className="dots">
                                    <img src="assets/img/banner_dot.png" alt="dot" className="dot-1" />
                                    <img src="assets/img/banner_dot.png" alt="dot" className="dot-2" />
                                </div>
                                <div className="bubble">
                                    <span className="bubble-1"></span>
                                    <span className="bubble-2"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="ptb-120">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-left text-lg-center mb-32 mb-lg-0">
                                <img src="assets/img/Why-chose-us-01.jpg" alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-right">
                                <h4 className="text-primary h5 mb-3">Why Choose Us</h4>
                                <h2 className="mb-4">
                                    We are working with <br />
                                    8 years experience
                                </h2>
                                <p>
                                    Xampera is the technology partner of choice for some of the world's leading enterprises, startups, and SMEs. By providing custom software development, product design, quality assurance, and consultancy services, we help businesses elevate their value.                                </p>
                                <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                                    <li className="py-1">
                                        <div className="d-flex about-icon-box align-items-center">
                                            <div className="me-3">
                                                <img src="assets/img/pie-chart.png" alt="" />
                                            </div>
                                            <div>
                                                <h5>Expert around the world</h5>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="py-1">
                                        <div className="d-flex about-icon-box align-items-center">
                                            <div className="me-3">
                                                <img src="assets/img/team.png" alt="" />
                                            </div>
                                            <div>
                                                <h5>Quick Support</h5>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                                <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                                    <li className="py-1">
                                        <i className="fad fa-check-circle me-2 text-primary"></i>
                                        Web Application
                                    </li>
                                    <li className="py-1">
                                        <i className="fad fa-check-circle me-2 text-primary"></i>
                                        ERP Software
                                    </li>
                                    <li className="py-1">
                                        <i className="fad fa-check-circle me-2 text-primary"></i>
                                        UI/UX Design
                                    </li>
                                    <li className="py-1">
                                        <i className="fad fa-check-circle me-2 text-primary"></i>
                                        Mobile Application
                                    </li>
                                    <li className="py-1">
                                        <i className="fad fa-check-circle me-2 text-primary"></i>
                                        SEO
                                    </li>
                                    <li className="py-1">
                                        <i className="fad fa-check-circle me-2 text-primary"></i>
                                        CRM Software
                                    </li>
                                </ul>
                                <Link to={about} className="link-with-icon text-decoration-none mt-3 btn btn-primary">
                                Learn More
                                    <i className="fas fa-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ptb-120 bg-dark">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6">
                            <div className="text-center">
                                <h2>Our Popular Services</h2>
                                <p>
                                    Building technology refers to creating software and hardware products using cutting-edge techniques and practices. Requires technical expertise, strong team, market understanding, and rapid prototyping. Committed to delivering high-quality, reliable products.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="service-tabs">
                                <ul className="nav nav-pills d-flex justify-content-center" id="pills-tab"
                                    role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active me-4" id="pills-home-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-home" type="button" role="tab"
                                            aria-controls="pills-home"
                                            aria-selected="true">
                                            <i className="fas fa-desktop me-3"></i>
                                            <span>UX/UI Design</span>
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link me-4" id="pills-profile-tab" data-bs-toggle="pill"
                                            data-bs-target="#pills-profile" type="button" role="tab"
                                            aria-controls="pills-profile" aria-selected="false">
                                            <i className="fas fa-tablet-alt me-3"></i>
                                            <span>App Development</span>
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill"
                                            data-bs-target="#pills-contact" type="button" role="tab"
                                            aria-controls="pills-contact" aria-selected="false">
                                            <i className="fas fa-vector-square me-2"></i>
                                            <span>Web Applications</span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                                aria-labelledby="pills-home-tab">
                                <div className="tab-content-wrapper pt-60">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="text-center mb-5 mb-lg-0">
                                                <img src="assets/img/tab_img1.png" alt="" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="tab-right-content">
                                                <h2>
                                                    We designed The Best UI/UX For Your Website or Web Application
                                                </h2>
                                                <p>
                                                    Designing the best UI/UX means creating an intuitive, user-friendly interface for a website or web application. UI design focuses on visual elements, UX design on overall experience. Consider target audience, business goals, and user tasks. User testing and research to understand behaviors, preferences, and pain points. Aesthetic, easy to use, and meets user and business needs.
                                                </p>
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <i className="fas fa-check text-primary"></i>
                                                        <span>Web-Site Designing</span>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check text-primary"></i>
                                                        <span>Mobile Application Designing</span>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check text-primary"></i>
                                                        <span>Game UI/UX Designing</span>
                                                    </li>
                                                </ul>
                                                <Link to={uIUXDDesigning} className="text-white link-with-icon text-decoration-none">
                                                    Know More About Us
                                                    <i className="fas fa-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-profile" role="tabpanel"
                                aria-labelledby="pills-contact-tab">
                                <div className="tab-content-wrapper pt-60">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="mb-5 mb-lg-0">
                                                <img src="assets/img/Untitled-1.png" alt="" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="tab-right-content">
                                                <h2>Mobile Apps We Develop</h2>
                                                <p>
                                                    Mobile app development services are aimed at building iOS & Android applications that effectively complement or substitute web solutions.
                                                </p>
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <i className="fas fa-check text-primary"></i>
                                                        <span>Mobile banking, billing apps, wallets</span>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check text-primary"></i>
                                                        <span>Patient and hospital apps</span>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check text-primary"></i>
                                                        <span>Messaging and VoIP apps</span>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check text-primary"></i>
                                                        <span>E-Commerce apps</span>
                                                    </li>
                                                </ul>
                                                <Link to={mobileAppDevelopment} className="text-white link-with-icon text-decoration-none">
                                                    Read More
                                                    <i className="fas fa-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <div className="tab-content-wrapper pt-60">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="pe-5 mb-5 mb-lg-0">
                                                <img src="assets/img/Untitled-2.png" alt="" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="tab-right-content">
                                                <h2>We Develop Best Web Applications</h2>
                                                <p>
                                                    Web applications are software programs accessed through a web browser, built using web technologies, and hosted on a web server. Can provide same features as desktop apps, accessible and convenient. Consider user experience, security, scalability, and target audience. Requires technical skills and understanding of user needs.
                                                </p>
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <i className="fas fa-check text-primary"></i>
                                                        <span>Enterprise Web Apps</span>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check text-primary"></i>
                                                        <span>Web Portals</span>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check text-primary"></i>
                                                        <span>Customer-facing Apps</span>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check text-primary"></i>
                                                        <span>Ecommerce</span>
                                                    </li>
                                                </ul>
                                                <Link to={webAppDevelopment} className="text-white link-with-icon text-decoration-none">
                                                    Read More
                                                    <i className="fas fa-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className="feature-promo ptb-120 bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="section-heading text-center">
                                <h2>With all the Features You Need</h2>
                                <p>Credibly grow premier ideas rather than bricks-and-clicks strategic theme areas
                                    distributed for stand-alone web-readiness.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feature-promo p-lg-5 p-4 text-center mt-3">
                                <div className="feature-icon icon-center pb-5 rounded-custom bg-primary">
                                    <i className="fal fa-layer-group fa-2x text-white"></i>
                                </div>
                                <div className="feature-info-wrap">
                                    <h3 className="h5">Good Performance</h3>
                                    <p>
                                        Performance in software refers to its speed, efficiency, and scalability. It is important for user satisfaction and system reliability. Improving performance involves optimizing algorithms, reducing resource usage, and parallelizing processes.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feature-promo p-lg-5 p-4 text-center mt-3">
                                <div className="feature-icon icon-center pb-5 rounded-custom bg-danger">
                                    <i className="fal fa-shield-check fa-2x text-white"></i>
                                </div>
                                <div className="feature-info-wrap">
                                    <h3 className="h5">Highly Secure</h3>
                                    <p>
                                        Highly secure software is designed to protect sensitive data and prevent unauthorized access. It uses encryption, secure authentication, and access controls. Regular testing and updates are crucial for maintaining security. It is important in industries handling sensitive information.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feature-promo p-lg-5 p-4 text-center mt-3">
                                <div className="feature-icon icon-center pb-5 rounded-custom bg-dark">
                                    <i className="fal fa-code fa-2x text-white"></i>
                                </div>
                                <div className="feature-info-wrap">
                                    <h3 className="h5">Fast Development</h3>
                                    <p>
                                        Fast development means creating, testing, and deploying software quickly using agile methodologies, modern tools, and cloud infrastructure. It improves time-to-market and allows for frequent releases. Requires collaboration, communication, and continuous integration and deployment.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-lg-5 pt-3">
                        <div className="col-lg-6 mt-4">
                            <div
                                className="position-relative d-flex flex-column h-100 flex-wrap bg-danger-soft p-5 rounded-custom">
                                <div className="cta-left-info mb-2">
                                    <h5>Utilize your software data</h5>
                                    <p>
                                        Utilizing software data means making use of data generated by software systems to achieve business goals and insights. This involves collecting, storing, and analyzing data from various sources and using insights to inform decision-making, improve user experiences, and drive growth. A clear strategy, data visualization tools, and secure data management are important for effective utilization.
                                    </p>
                                </div>
                                <div className="cta-img position-absolute right-0 bottom-0">
                                    <img src="assets/img/cta-img-1.png" alt="cta img" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4">
                            <div
                                className="position-relative d-flex flex-column h-100 flex-wrap bg-primary-soft p-5 rounded-custom">
                                <div className="cta-left-info mb-2">
                                    <h5>Get real time updated anytime</h5>
                                    <p>
                                        Getting real-time updates anytime means accessing information as soon as it becomes available, through real-time data streams and event-driven architecture. This provides users with immediate notifications and live updates. Effective real-time updates require the necessary infrastructure, reliability, and scalability.
                                    </p>
                                </div>
                                <div className="cta-img position-absolute right-0 bottom-0">
                                    <img src="assets/img/cta-img-2.png" alt="cta img" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section className="services-icon ptb-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="section-heading text-center">
                                <h2>Services We Provide</h2>
                                <p>
                                    Credibly grow premier ideas rather than bricks-and-clicks strategic
                                    theme areas distributed for stand-alone web-readiness.
                                </p>
                            </div>
                            ,
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        {WebSerices.map((data, index) => {
                            return (
                                <div className="col-lg-4 col-md-6 p-0">
                                    <div className="single-service homeser p-lg-5 p-4 text-center mt-3 border-bottom border-end">
                                        <Link to={data.link}>
                                            <div className="service-icon icon-center">
                                                <img src={data.logo} width={"60px"} />
                                            </div>
                                            <div className="service-info-wrap">
                                                <h3 className="h5">{data.name}</h3>
                                                <p>
                                                    {data.shortDesc}
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            )
                        })}

                        {DigitalServices.map((data, index) => {
                            return (
                                <div className="col-lg-4 col-md-6 p-0">
                                    <div className="single-service p-lg-5 p-4 text-center mt-3 homeser border-bottom border-end">
                                        <Link to={data.link}>
                                            <div className="service-icon icon-center">
                                                <img src={data.logo} width={"60px"} />
                                            </div>
                                            <div className="service-info-wrap">
                                                <h3 className="h5">{data.name}</h3>
                                                <p>
                                                    {data.shortDesc}
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </section>

            <section className="integration-section pb-80">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-3">
                            <div className="integration-list-wrap">
                                <a href="integration-single.html" className="integration-1" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Your Brand Name">
                                    <img src="assets/img/integations/1.png" alt="integration"
                                        className="img-fluid rounded-circle" />
                                </a>
                                <a href="integration-single.html" className="integration-2" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Your Brand Name">
                                    <img src="assets/img/integations/2.png" alt="integration"
                                        className="img-fluid rounded-circle" />
                                </a>
                                <a href="integration-single.html" className="integration-3" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Your Brand Name">
                                    <img src="assets/img/integations/3.png" alt="integration"
                                        className="img-fluid rounded-circle" />
                                </a>

                                <a href="integration-single.html" className="integration-4" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Your Brand Name">
                                    <img src="assets/img/integations/4.png" alt="integration"
                                        className="img-fluid rounded-circle" />
                                </a>
                                <a href="integration-single.html" className="integration-5" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Your Brand Name">
                                    <img src="assets/img/integations/5.png" alt="integration"
                                        className="img-fluid rounded-circle" />
                                </a>
                                <Link to={intigrations} className="integration-6" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Your Brand Name">
                                    <img src="assets/img/integations/6.png" alt="integration"
                                        className="img-fluid rounded-circle" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="section-heading text-center my-5 my-lg-0 my-xl-0">
                                <h4 className="text-primary h5">Integration</h4>
                                <h2>We Collaborate with Top Software Company</h2>
                                <Link to={intigrations} className="mt-4 btn btn-primary">View all Integration</Link>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="col-lg-4">
                                <div className="integration-list-wrap">
                                    <a href="integration-single.html" className="integration-7" data-bs-toggle="tooltip"
                                        data-bs-placement="top" title="Your Brand Name">
                                        <img src="assets/img/integations/7.png" alt="integration"
                                            className="img-fluid rounded-circle" />
                                    </a>
                                    <a href="integration-single.html" className="integration-8" data-bs-toggle="tooltip"
                                        data-bs-placement="top" title="Your Brand Name">
                                        <img src="assets/img/integations/8.png" alt="integration"
                                            className="img-fluid rounded-circle" />
                                    </a>
                                    <a href="integration-single.html" className="integration-9" data-bs-toggle="tooltip"
                                        data-bs-placement="top" title="Your Brand Name">
                                        <img src="assets/img/integations/9.png" alt="integration"
                                            className="img-fluid rounded-circle" />
                                    </a>

                                    <a href="integration-single.html" className="integration-10"
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Your Brand Name">
                                        <img src="assets/img/integations/10.png" alt="integration"
                                            className="img-fluid rounded-circle" />
                                    </a>
                                    <a href="integration-single.html" className="integration-11"
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Your Brand Name">
                                        <img src="assets/img/integations/11.png" alt="integration"
                                            className="img-fluid rounded-circle" />
                                    </a>
                                    <a href="integration-single.html" className="integration-12"
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Your Brand Name">
                                        <img src="assets/img/integations/12.png" alt="integration"
                                            className="img-fluid rounded-circle" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}
export default Home
