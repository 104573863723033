import React from "react";
import "./App.css"
import $ from 'jquery';
import {Routes, Route, Link, useNavigate} from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import {home, about, contact, uIUXDDesigning, webAppDevelopment,webSiteDevelopment,cRMDevelopment,
    intigrations,mobileAppDevelopment,digitalMarketing,eRPDevelopment,sEO,graphicDesigning,contentCreation,allServices,termsConditions} from "./constants/helpers";
import UIUXDDesigning from './pages/UIUXDDesigning';
import WebAppDevelopment from "./pages/WebAppDevelopment";
import WebSiteDevelopment from './pages/WebSiteDevelopment'
import CRMDevelopment from './pages/CRMDevelopment';
import Intigrations from './pages/Intigrations';
import MobileAppDevelopment from "./pages/MobileAppDevelopment";
import Contact from "./pages/Contact";
import DigitalMarketing from "./pages/DigitalMarketing";
import ERPDevelopment from "./pages/ERPDevelopment"
import SEO from "./pages/SEO"
import GraphicDesigning from "./pages/GraphicDesigning"
import ContentCreation from "./pages/ContentCreation";
import Services from "./pages/Services";
import ContactSupport from "./components/ContactSupport";
import TermsConditions from "./pages/TermsCondition";
function App() {
    return (
        <>

            {/*<div id="preloader">*/}
            {/*    <div className="preloader-wrap">*/}
            {/*        <img src="assets/img/favicon.png" alt="logo" className="img-fluid preloader-icon"/>*/}
            {/*        <div className="loading-bar"></div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <Header/>

            <div className="main-wrapper">
                <Routes>
                    <Route  path={home}  element={<Home/>}/>
                    <Route  path={about}  element={<About/>}/>
                    <Route  path={contact}  element={<Contact/>}/>
                    <Route  path={uIUXDDesigning}  element={<UIUXDDesigning/>}/>
                    <Route  path={webAppDevelopment}  element={<WebAppDevelopment/>}/>
                    <Route  path={webSiteDevelopment}  element={<WebSiteDevelopment/>}/>
                    <Route  path={cRMDevelopment}  element={<CRMDevelopment/>}/>
                    <Route  path={intigrations}  element={<Intigrations/>}/>
                    <Route  path={mobileAppDevelopment}  element={<MobileAppDevelopment/>}/>
                    <Route  path={digitalMarketing}  element={<DigitalMarketing/>}/>
                    <Route  path={eRPDevelopment}  element={<ERPDevelopment/>}/>
                    <Route  path={sEO}  element={<SEO/>}/>
                    <Route  path={graphicDesigning}  element={<GraphicDesigning/>}/>
                    <Route  path={contentCreation}  element={<ContentCreation/>}/>
                    <Route  path={allServices}  element={<Services/>}/>
                    <Route  path={termsConditions}  element={<TermsConditions/>}/>
                </Routes>
            </div>

            <ContactSupport/>
            <Footer/>
        </>
    );
}

export default App;
