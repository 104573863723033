import React,{useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {IntigrationLIst, intigrations} from "../constants/helpers";
import {Link} from "react-router-dom";
import IntigrationList from "../components/IntigrationList";
import {Helmet} from "react-helmet";
import {WebSerices} from "../constants/helpers";
const WebSiteDevelopment = () => {
    useEffect(() => {
        AOS.init();
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    let data = WebSerices.find(el => el.id === "3");
    return(
        <>
            <Helmet>
                <title> {data.metaTitle}</title>
                <meta name="description" content={data.shortDesc}/>
                <meta name="keywords" content={data.metaKeyword}/>
                <meta name="author" content="Abdul Hanan"/>
            </Helmet>
            <section className="hero-section ptb-120 bg13">
                <div className="container">
                    <div className="row align-items-center justify-content-lg-between">
                        <div className="col-xl-5 col-lg-5">
                            <div className="hero-content-wrap text-center text-xl-start text-lg-start"
                                 data-aos="fade-right">
                                <h1 className="fw-bold display-5">We Develop Best Website for Your Business</h1>
                                <p className="lead">
                                    Website development is the process of creating and maintaining websites, involving designing, coding, and content creation to create a functional and visually appealing website.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 mt-4 mt-xl-0">
                            <div className="hero-img-wrap position-relative" data-aos="fade-left">
                                <ul className="position-absolute animate-element parallax-element shape-service hide-medium">
                                    <li className="layer" data-depth="0.03">
                                        <img src="assets/img/color-shape/image-1.svg" alt="shape"
                                             className="img-fluid position-absolute color-shape-1"/>
                                    </li>
                                    <li className="layer" data-depth="0.02">
                                        <img src="assets/img/color-shape/feature-2.svg" alt="shape"
                                             className="img-fluid position-absolute color-shape-2 z-5"/>
                                    </li>
                                    <li className="layer" data-depth="0.03">
                                        <img src="assets/img/color-shape/feature-3.svg" alt="shape"
                                             className="img-fluid position-absolute color-shape-3"/>
                                    </li>
                                </ul>

                                <div className="hero-img-wrap position-relative">
                                    <div className="hero-screen-wrap">
                                        <div className="phone-screen">
                                            <img src="assets/img/screen/phone-screen.png" alt="hero image"
                                                 className="position-relative img-fluid"/>
                                        </div>
                                        <div className="mac-screen">
                                            <img src="assets/img/screen/mac-screen.png" alt="hero image"
                                                 className="position-relative img-fluid rounded-custom" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work-process ptb-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="section-heading text-center" data-aos="fade-up">
                                <h4 className="h5 text-primary">Process</h4>
                                <h2>We Follow Our Work Process</h2>
                                <p>Enthusiastically engage cross-media leadership skills for alternative experiences.
                                    Proactively drive vertical systems than intuitive architectures.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-5 col-md-12 order-1 order-lg-0">
                            <div className="img-wrap" data-aos="fade-up" data-aos-delay="50">
                                <img src="assets/img/office-img-1.png" alt="work process"
                                     className="img-fluid rounded-custom" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 order-0 order-lg-1">
                            <ul className="work-process-list list-unstyled">
                                <li className="d-flex align-items-start mb-4" data-aos="fade-up" data-aos-delay="50">
                                    <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                                        <img src="assets/img/implementation.svg"/>
                                    </div>
                                    <div className="icon-content">
                                        <span className="text-primary h6">Step 1</span>
                                        <h3 className="h5 mb-2">Full-cycle website design and development</h3>
                                        <p>
                                            Creating a website from scratch, including requirements gathering, design, implementation, quality assurance as well as maintenance and support.
                                        </p>
                                    </div>
                                </li>
                                <li className="d-flex align-items-start mb-4" data-aos="fade-up" data-aos-delay="100">
                                    <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                                        <img src="assets/img/redesign.svg"/>
                                    </div>
                                    <div className="icon-content">
                                        <span className="text-primary h6">Step 2</span>
                                        <h3 className="h5 mb-2">Redesign</h3>
                                        <p>Porting your legacy website, including all the data, to a new, modern solution (it can be another content management system) with a slick and responsive user interface.
                                        </p>
                                    </div>
                                </li>
                                <li className="d-flex align-items-start mb-4" data-aos="fade-up" data-aos-delay="150">
                                    <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                                        <img src="assets/img/integration.svg"/>
                                    </div>
                                    <div className="icon-content">
                                        <span className="text-primary h6">Step 3</span>
                                        <h3 className="h5 mb-2">Web application development and integration</h3>
                                        <p>
                                            Enriching your website with out-of-the-box and custom social networking apps, payment solutions, advanced analytics and other tools to increase user engagement.
                                        </p>
                                    </div>
                                </li>
                                <li className="d-flex align-items-start mb-4 mb-lg-0" data-aos="fade-up"
                                    data-aos-delay="200">
                                    <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                                        <img src="assets/img/migration-to-cloud.svg"/>
                                    </div>
                                    <div className="icon-content">
                                        <span className="text-primary h6">Step 4</span>
                                        <h3 className="h5 mb-2">Migration to cloud</h3>
                                        <p>
                                            Moving your existing website and applications to Amazon Web Services (AWS), Microsoft Azure, Google Cloud Platform and other cloud services to improve scalability and administration and lessen costs
                                        </p>
                                    </div>
                                </li>
                                <li className="d-flex align-items-start mb-4 mb-lg-0" data-aos="fade-up"
                                    data-aos-delay="200">
                                    <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                                        <img src="assets/img/support.svg"/>
                                    </div>
                                    <div className="icon-content">
                                        <span className="text-primary h6">Step 5</span>
                                        <h3 className="h5 mb-2">Maintenance and support</h3>
                                        <p>
                                            Creating new features and fixing bugs, Enhancing scalability and performance to welcome the growing number of visitors and data, Improving the website structure to better address user demand
                                            Increasing compliance with SEO standards for advanced content marketing strategies, Performing security audit and updates to protect data and users
                                        </p>
                                    </div>
                                </li>
                                <li className="d-flex align-items-start mb-4 mb-lg-0" data-aos="fade-up"
                                    data-aos-delay="200">
                                    <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                                        <img src="assets/img/mobile-first-website.svg"/>
                                    </div>
                                    <div className="icon-content">
                                        <span className="text-primary h6">Step 5</span>
                                        <h3 className="h5 mb-2">Mobile-driven development</h3>
                                        <p>
                                            Adapting your website for mobile phones and tablets of all platforms and screen sizes as well as using the portal as a back-end for a mobile app.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>



            <section className="promo-section ptb-120 bg-light ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-6">
                            <div className="section-heading text-center" data-aos="fade-up">
                                <h2>Our Customers Get Results</h2>
                                <p>Progressively deploy market positioning catalysts for change and technically sound.
                                    Authoritatively with backward-compatible
                                    e-services. </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4 mt-4 mt-lg-0 mt-md-0">
                            <div className="bg-dark p-5 text-center d-flex flex-column h-100 rounded-custom"
                                 data-aos="fade-up" data-aos-delay="100">
                                <div className="promo-card-info mb-4">
                                    <h3 className="display-5 fw-bold mb-4"><i
                                        className="far fa-fingerprint text-warning me-2"></i> 10x
                                    </h3>
                                    <p>Embrace distinctive best practices after B2B syndicate backend internal or
                                        whereas edge
                                        process improvements. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mt-4 mt-lg-0 mt-md-0">
                            <div className="bg-dark p-5 text-center d-flex flex-column h-100 rounded-custom"
                                 data-aos="fade-up" data-aos-delay="150">
                                <div className="promo-card-info mb-4">
                                    <h3 className="display-5 fw-bold mb-4"><i
                                        className="far fa-paper-plane text-warning me-2"></i> 5k
                                    </h3>
                                    <p>Rapidiously embrace distinctive best practices B2B syndicate backend internal or
                                        whereas
                                        process improvements. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mt-4 mt-lg-0">
                            <div className="bg-dark p-5 text-center d-flex flex-column h-100 rounded-custom"
                                 data-aos="fade-up" data-aos-delay="200">
                                <div className="promo-card-info mb-4">
                                    <h3 className="display-5 fw-bold mb-4"><i
                                        className="far fa-chart-pie-alt text-warning me-2"></i>
                                        95%</h3>
                                    <p>Distinctive best practices after B2B syndicate internal or whereas bleeding-edge
                                        process
                                        improvements. </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="feature-section-two ptb-120">
                <div className="container">
                    <div className="row align-items-center justify-content-lg-between justify-content-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="feature-content-wrap">
                                <div className="section-heading">
                                    <h2>We are development experts on all technologies</h2>
                                    <p>Quickly incubate functional channels with multidisciplinary architectures authoritatively
                                        fabricate formulate exceptional innovation.</p>
                                </div>
                                <ul className="list-unstyled mb-0">
                                    <li className="d-flex align-items-start mb-4">
                                        <div className="icon-box bg-primary rounded me-4">
                                            <i className="fas fa-bezier-curve text-white"></i>
                                        </div>
                                        <div className="icon-content">
                                            <h3 className="h5">Pixel Perfect Design</h3>
                                            <p>
                                                The goal of pixel perfect design is to create a visual design that appears flawless and visually precise, with every element precisely placed and aligned to a specific grid. This approach is often used in web design, graphic design, and user interface design, where precise placement and alignment of elements can have a significant impact on the overall look and feel of a design.
                                            </p>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-start mb-4">
                                        <div className="icon-box bg-danger rounded me-4">
                                            <i className="fas fa-fingerprint text-white"></i>
                                        </div>
                                        <div className="icon-content">
                                            <h3 className="h5">Unique &amp; Minimal Design</h3>
                                            <p> In this approach, designers strive to eliminate unnecessary elements and use a minimal color palette and clean, simple lines to create a clean and uncluttered design. The focus is on creating a design that is visually impactful while being simple and easy to understand.
                                            </p>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-start mb-4 mb-lg-0">
                                        <div className="icon-box bg-dark rounded me-4">
                                            <i className="fas fa-headset text-white"></i>
                                        </div>
                                        <div className="icon-content">
                                            <h3 className="h5">24/7 Free Online Support</h3>
                                            <p>
                                                We provide 24/7 Free Online Support channels such as live chat, email, phone, or social media. The goal of 24/7 support is to provide customers with quick and convenient assistance whenever they need it, which can help improve customer satisfaction and loyalty.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-7">
                            <div className="feature-img-wrap">
                                <img src="assets/img/feature-hero-img.svg" alt="feature image" className="img-fluid rounded-custom"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <IntigrationList/>
            
        </>
    )
}
export default WebSiteDevelopment
