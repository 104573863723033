import React from "react";
import {Link} from "react-router-dom";
import {about, contact, DigitalServices, home, WebSerices,termsConditions} from "../constants/helpers";
import {intigrations} from "../constants/helpers";

const Footer = () => {
    return(
        <>
            <footer className="footer-section">
                <div className="footer-top  bg-gradient text-white ptb-120 bg2">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-md-8 col-lg-4 mb-md-4 mb-lg-0">
                                <div className="footer-single-col">
                                    <div className="footer-single-col mb-4">
                                        <img src="assets/img/logowhite.png" alt="logo"
                                             className="img-fluid logo-white" style={{borderRadius:"10px"}}/>
                                        <img src="assets/img/logowhite.png" alt="logo"
                                             className="img-fluid logo-color" style={{borderRadius:"10px"}}/>
                                    </div>
                                    <p>Xampera is the technology partner of choice for some of the world's leading enterprises, startups, and SMEs. By providing custom software development, product design, quality assurance, and consultancy services, we help businesses elevate their value.</p>

                                    <div className="ratting-wrap mt-4">
                                        <h6 className="mb-0">10/10 Overall rating</h6>
                                        <ul className="list-unstyled rating-list list-inline mb-0">
                                            <li className="list-inline-item"><i
                                                className="fas fa-star text-warning"></i></li>
                                            <li className="list-inline-item"><i
                                                className="fas fa-star text-warning"></i></li>
                                            <li className="list-inline-item"><i
                                                className="fas fa-star text-warning"></i></li>
                                            <li className="list-inline-item"><i
                                                className="fas fa-star text-warning"></i></li>
                                            <li className="list-inline-item"><i
                                                className="fas fa-star text-warning"></i></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0">
                                <div className="row">
                                    <div className="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                                        <div className="footer-single-col">
                                            <h3>Menu</h3>
                                            <ul className="list-unstyled footer-nav-list mb-lg-0">
                                                <li><Link to={home} className="text-decoration-none">Home</Link></li>
                                                <li><Link to={about} className="text-decoration-none">About
                                                    Us</Link></li>
                                                <li><Link to={home}
                                                       className="text-decoration-none">Services</Link></li>
                                                {/*<li><a href="career.html" className="text-decoration-none">Career</a>*/}
                                                {/*</li>*/}
                                                <li><Link to={intigrations}
                                                       className="text-decoration-none">Integrations</Link>
                                                </li>
                                                <li><Link to={contact} className="text-decoration-none">Contact US</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                                        <div className="footer-single-col">
                                            <h3>Web & App Services</h3>
                                            <ul className="list-unstyled footer-nav-list mb-lg-0">
                                                {WebSerices.map((data,index)=>{
                                                    return(
                                                        <li><Link to={data.link} className="text-decoration-none">{data.name}</Link></li>
                                                    )
                                                })}

                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                                        <div className="footer-single-col">
                                            <h3>Digital Services</h3>
                                            <ul className="list-unstyled footer-nav-list mb-lg-0">

                                                    {DigitalServices.map((data,index)=>{
                                                        return(
                                                            <li><Link to={data.link}
                                                                   className="text-decoration-none">{data.name}</Link></li>
                                                        )
                                                    })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="footer-bottom  bg-gradient text-white py-4">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-7 col-lg-7">
                                <div className="copyright-text">
                                    <p className="mb-lg-0 mb-md-0">&copy; 2023 Xampera Rights Reserved. Designed By 
                                       <Link to={home} className="text-decoration-none"> <strong>Xampera</strong></Link> &nbsp;
                                       &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                       <Link  to={termsConditions} className="text-decoration-none">Terms & Condition</Link> 
                                       </p>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4">
                                <div className="footer-single-col text-start text-lg-end text-md-end">
                                    <ul className="list-unstyled list-inline footer-social-list mb-0">
                                        <li className="list-inline-item"><a href="https://www.facebook.com/Xamperies/" target={"_blank"}><i className="fab fa-facebook-f"></i></a></li>
                                        <li className="list-inline-item"><a href="https://www.instagram.com/xamperies/" target={"_blank"}><i className="fab fa-instagram"></i></a></li>
                                        <li className="list-inline-item"><a href="https://twitter.com/xamperaSoftware" target={"_blank"}><i className="fab fa-twitter"></i></a></li>
                                        <li className="list-inline-item"><a href="mailto:xamperasoftwarehouse@gmail.com" target={"_blank"}><i className="fab fa-google"></i></a></li>
                                        <li className="list-inline-item"><a href="https://www.linkedin.com/company/xampera/" target={"_blank"}><i className="fab fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </footer>
        </>
    )
}
export default Footer
