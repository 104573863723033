import React from "react";
import {Link} from "react-router-dom";
import {IntigrationLIst, intigrations} from "../constants/helpers";

const IntigrationList = () => {
    return(
        <>
            <section className="integration-section  ptb-120">
                <div className="container">
                    <div className="row align-items-center justify-content-lg-between">
                        <div className="col-lg-6 col-md-12">
                            <div className="section-heading">
                                <h4 className="h5 text-primary">Integration</h4>
                                <h2>We Collaborate with Top Software Company</h2>
                                <p>Dynamically pursue convergence rather than 24/7 process improvements
                                    develop end-to-end customer service action items.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="text-lg-end mb-5 mb-lg-0">
                                <Link to={intigrations} className="btn btn-primary">View All Integrations</Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="integration-wrapper position-relative w-100">
                                <ul className="integration-list list-unstyled mt-5">
                                    {IntigrationLIst.map((data,index)=>{
                                        return(
                                            <li key={index+data.id}>
                                                <div className="single-integration">
                                                    <img src={data.logo} alt="integration"
                                                         className="img-fluid"/>
                                                    <h6 className="mb-0 mt-4">{data.name}</h6>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default IntigrationList
